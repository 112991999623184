import { render, staticRenderFns } from "./CardResponse.vue?vue&type=template&id=281fc60d&scoped=true&"
import script from "./CardResponse.vue?vue&type=script&lang=js&"
export * from "./CardResponse.vue?vue&type=script&lang=js&"
import style0 from "./CardResponse.vue?vue&type=style&index=0&id=281fc60d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "281fc60d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRating } from 'vuetify/lib/components/VRating';
installComponents(component, {VRating})
