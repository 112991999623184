<template>
  <div class="page-content">
    <div class="container">

      <FilterBtnList
        responsive
      ></FilterBtnList>

      <ControlPanel>
        <template #body>
          <FilterResponseList
            class="filter--content"
            :request-list="serviceRequestList"
            v-model="request"
            @applyFilters="onApplyFilters"
          ></FilterResponseList>
        </template>
      </ControlPanel>

      <template v-if="$route.query.request">
        <Breadcrumbs :items="breadcrumbsList"></Breadcrumbs>
      </template>

      <template v-if="loading">

        <v-progress-linear
          indeterminate
        ></v-progress-linear>

      </template>
      <template v-else-if="responses.length">

        <h1 class="page-content__title">
          <template v-if="$route.query.request">На вашу заявку №{{ $route.query.request }} откликнулись</template>
          <template v-else>На ваши заявки откликнулись</template>
        </h1>

        <div class="responses-list">
          <template v-for="(response, index) in normalizeResponses">
            <div class="responses-list__ell" :key="index">

              <CardResponse
                :request-id="response.requestId"
                :id="response.id"
                :name="response.name"
                :position="response.position"
                :photo="response.photo"
                :rate="response.rate"
                :class="response.status"
              />

            </div>
          </template>
        </div>

        <InfiniteLoading
          :forceUseInfiniteWrapper="true"
          :distance="500"
          error="Opps, something went wrong. Reload page."
          @infinite="infiniteHandler"
        >

          <div slot="no-more" />
          <div slot="no-results" />

        </InfiniteLoading>

      </template>
      <template v-else>

        <p>Нет откликов</p>

      </template>
    </div>
  </div>
</template>

<script>

import {
  ASSISTANCE_NEED_RESPONSES,
  ASSISTANCE_NEED_APPLICATION
} from '@router/variable';
import { mapState } from 'vuex';
import CardResponse from "@component/Module/CardResponse";
import FilterBtnList from "@component/Module/NeedServiceRequestList/FilterBtnList";
import Breadcrumbs from "@component/UI/Breadcrumbs";
import InfiniteLoading from "vue-infinite-loading";
import ControlPanel from "@component/Module/ControlPanel";
import FilterResponseList from "@component/Module/FilterResponseList";

import { preparePhoto } from '@library/helpers';

export default {
  components: {
    FilterResponseList,
    CardResponse,
    ControlPanel,
    FilterBtnList,
    Breadcrumbs,
    InfiniteLoading
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    normalizeResponses() {
      return this.responses.reduce((result, {
        id,
        user: { first_name, last_name, photo, rate_avg },
        request: { id: requestId }
      }) => {
        result.push({
          requestId,
          id,
          name: `${first_name} ${last_name}`,
          photo: preparePhoto(photo),
          rate: rate_avg
        });
        return result;
      }, []);
    },
    breadcrumbsList() {
      return [
        {
          text: 'Все отклики',
          disabled: false,
          exact: true,
          // listeners: {
          //   click: () => this.onApplyFilters()
          // },
          to: {name: ASSISTANCE_NEED_RESPONSES},
        },
        {
          text: `Отклики заявки № ${this.$route.query.request}`,
          disabled: true
        },
      ];
    }
  },
  data() {
    return {
      request: null,
      loading: true,
      responses: [],
      serviceRequestList: [],
      page: 1,
      pageSize: 10
    };
  },
  watch: {
    async '$route.query'(queries) {
     if (!queries.request) {
       this.request = null;
       await this.onApplyFilters();
     }
    }
  },
  methods: {
    async infiniteHandler($state) {
      if (this.responses.length < this.pageSize) {
        $state.complete();
        return;
      }

      this.page += 1;
      const responseList = await this.getResponseList();

      if (!responseList || responseList.length < this.pageSize) {
        $state.complete();
        console.log('complete');
      } else {
        $state.loaded();
        console.log('loaded');
      }
    },
    async getResponseList() {

      try {
        const {applicationId} = this.$route.params;

        const params = {
          page: this.page,
          page_size: this.pageSize
        };

        const responses = this.request
          ? await RequestManager.Service.getResponsesOfRequest(this.user.id, this.request, params)
          : await RequestManager.Service.getProviderResponses(this.user.id, params);

        if (responses.length) {
          this.responses.push(...responses);
        }

        return responses;

      } catch (err) {
        console.log(err);
      }
    },

    async getServiceRequestList() {
      try {
        this.serviceRequestList = await RequestManager.Service.getRequests(
          this.user.id,
          {
            status: this.$ENUM.REQUEST_STATUSES.ACTIVE,
            parent_is_null: true
          }
        );
      } catch (err) {
        console.log(err);
      }
    },

    async onApplyFilters() {
      this.page = 1;
      this.loading = true;
      this.responses = [];

      const query = {};
      if (this.request) {
        query.request = this.request;
      }
      if (
        !this.request && this.$route.query?.request
        || ( 'request' in this.$route.query && this.request !== this.$route.query.request)
        || !this.$route.query?.request && this.request
      ) {
        await this.$router.push({ query });
      }
      // if (this.$route.query.request !== this.request) {}

      await this.getResponseList()
        .finally(() => this.loading = false);
    }
  },
  async mounted() {

    this.request = this.$route.query?.request ? Number(this.$route.query.request) : null;
    await this.getResponseList();
    await this.getServiceRequestList();

    if (this.responses) this.loading = false;
  }
};
</script>

<style lang="scss" scoped>

// responses-list
.responses-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -13px;

  @media(max-width: 992px) {
    flex-wrap: wrap;
  }
  .responses-list__ell {
    width: 33.333%;
    margin-bottom: 15px;
    padding: 0 13px;

    @media(max-width: 992px) {
      width: 48%;
    }

    @media(max-width: 768px) {
      width: 100%;
    }
  }
}

</style>
