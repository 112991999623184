<template>
  <div class="filter filter_content">
    <div class="filter__title">Фильтр</div>
    <v-row class="filter__block">
      <v-col cols="12" md="6">
        <!--      <div class="filter__label">Выберите одну из активных заявок</div>-->
        <Select
          label="Активные заявки"
          v-on="$listeners"
          :items="normalizeRequestList"
          :value="value"
          item-text="name"
          item-value="id"
        ></Select>
      </v-col>
    </v-row>
    <div class="filter__content">
      <button @click="$emit('applyFilters')" class="btn btn--prime">Применить</button>
      <button @click="onResetFilters" class="filter__btn-reset">Сбросить данные фильтра</button>
    </div>
  </div>
</template>

<script>
import Select from "@component/UI/Form/Select";
export default {
  name: "FilterResponseList",
  components: {
    Select
  },
  props: {
    requestList: {
      type: Array,
      default: () => []
    },
    value: {
      default: null
    }
  },
  computed: {
    normalizeRequestList() {
      if (!this.requestList || !this.requestList.length) return [];
      let requestList = this.requestList.reduce((result, request) => {
        const { id, responsibility: { name } } = request;
        result.push({
          id,
          name: `Заявка №${id} — ${name}`
        });

        return result;
      }, []);

      requestList = requestList.sort(({id : a}, { id: b}) => {
        return a - b;
      });

      return [
        {
          name: 'Не выбрано',
          id: null
        },
        ...requestList
      ];
    }
  },
  methods: {
    onResetFilters() {
      this.$emit('input',null);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(163, 163, 163, 0.3);
  margin: 0px -14px;
  .btn {
    width: 100%;
    max-width: 343px;
    margin: 0 auto;
  }
  .filter__label {
    margin-bottom: 16px;
    padding: 14px 16px;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    background-color: #ededf4;
  }
  .filter__btn-reset {
    display: block;
    margin: 20px auto 0;
    color: #b3b3b8;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    transition: all 0.2s ease-in-out;
    @include respond-to(sm) {
      margin: 24px auto 0;
      font-size: 18px;
    }
    &:hover {
      color: var(--color-prime-active);
    }
  }
  &.filter_content {
    margin: 0;
    position: relative;
    padding: 30px 14px;
    background: #ffffff;
    border: 1px solid rgba(118, 118, 128, 0.12);
    border-radius: 10px;
    border-bottom: none;
    .filter__title {
      display: block;
      margin-bottom: 16px;
      color: #000000;
      font-weight: 600;
      font-size: 16px;
      @include respond-to(sm) {
        margin-bottom: 28px;
        font-size: 19px;
      }
    }
    .filter__label {
      border-radius: 5px;
    }
    .filter__block {
      margin-bottom: 20px;
    }
    .filter__content {
      padding: 0;
    }
    .filter__btn-reset {
      @include respond-to(md) {
        position: absolute;
        right: 15px;
        top: 30px;
        margin: 0;
      }
    }
  }
}
</style>
