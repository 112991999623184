<template>
  <router-link
    :to="{
      name: this.$routeName.ASSISTANCE_NEED_RESPONSE,
      params: { responseId: id, applicationId: requestId }
    }"
    class="card-response"
  >
    <div class="card-response__avatar">
      <img
        v-if="photo"
        class="card-response__img"
        :src="photo"
        alt=""
      >
      <img
        v-else
        class="card-response__img"
        :src="require('@img/user-avatar.svg')"
      >
    </div>
    <div class="card-response__content">
      <p class="card-response__request_id">Заявка № {{requestId }}</p>
      <p class="card-response__name">{{ name }}</p>
      <div class="rating">
        <v-rating
          hover
          readonly
          length="5"
          size="20"
          v-model="rate"
        ></v-rating>
        <div class="rating__vue">{{ rateVue }}</div>
      </div>
      <p class="card-response__position">{{ position }}</p>
    </div>
  </router-link>
</template>

<script>

export default {
  name: "CardResponse",
  props: {
    id: [Number, String],
    requestId: [Number, String],
    name: String,
    position: String,
    photo: String,
    rate: Number,
  },
  data() {
    return {
      rateVue: '',
    };
  },
};
</script>

<style scoped lang="scss">

// card-response
.card-response {
  display: flex;
  align-items: center;
  min-height: 95px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid rgba(118, 118, 128, 0.12);
  background-color: #FFFFFF;
  box-shadow: 0 3px 25px rgba(151, 161, 176, 0.25);
  transition: all .2s ease-in-out;
  cursor: pointer;

  @media(max-width: 992px) {
    flex-wrap: wrap;
  }
  &:hover {
    box-shadow: 0 1px 0 rgba(151, 161, 176, 0.25);
  }
  .card-response__avatar {
    flex-shrink: 0;
    width: 65px;
    height: 65px;
    min-width: 65px;
    min-height: 65px;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;

    @media(max-width: 992px) {
      margin-bottom: 15px;
    }

    .card-response__img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  .card-response__name {
    font-weight: 600;
    font-size: 17px;
    line-height: 18px;
    color: #020202;
    margin-bottom: 5px;
  }
  .card-response__position,
  .card-response__request_id {
    font-size: 13px;
    line-height: 18px;
  }

  .card-response__request_id {
    color: #0C76DA;
    margin-bottom: 5px;
  }

  .card-response__position {
    margin-top: 5px;
  }

  &--blue {
    border: 1px solid #0C72D6;
    .card-response__position {
      color: #0C76DA;
    }
  }
  &--green {
    border: 1px solid #35C759;
    .card-response__position {
      color: #35C759;
    }
  }
}
</style>
