<template>
  <v-breadcrumbs
    v-bind="$attrs"
    v-on="$listeners"
    class="breadcrumbs-custom"
  >
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "Breadcrumbs",
  inheritAttrs: false
};
</script>

<style lang="scss">

.breadcrumbs-custom {
  padding-left: 0;
  .v-breadcrumbs__item {
    color: var(--color-prime)
  }
  .v-breadcrumbs li:nth-child(2n) {
    padding: 0 6px;
  }
}

</style>
